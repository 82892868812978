import { NgIf } from '@angular/common';
import {
  Component,
  OnInit,
  Injector,
  inject,
  signal,
  computed,
} from '@angular/core';
import { OKTA_AUTH, OKTA_CONFIG } from '@okta/okta-angular';
import { ButtonDirective } from '@pst/button';
import { SpinnerComponent } from '@pst/spinner';

import { HeaderComponent } from './header/header.component';
import { HeaderMenuComponent } from './header/header-menu.component';

/**
 * A slightly modified version of the OktaCallbackComponent from @okta/okta-angular
 */
@Component({
  standalone: true,
  selector: 'pst-okta-login-callback',
  imports: [
    ButtonDirective,
    HeaderComponent,
    HeaderMenuComponent,
    NgIf,
    SpinnerComponent,
  ],
  template: `
    <header>
      <pst-header>
        <pst-header-menu />
      </pst-header>
    </header>

    <div class="mx-auto max-w-screen-lg text-center">
      <div class="text-center">
        <ng-container *ngIf="error()">
          <ng-container *ngIf="isNoAccessError()">
            <p class="mt-16">
              You do not have access to the PMS Self-Test Portal.
            </p>
            <p class="mt-4">
              Please request access, or click below to log out.
            </p>
          </ng-container>
          <ng-container *ngIf="!isNoAccessError()">
            <p class="mt-16">Authentication Error.</p>
            <p class="mt-4">Please try again, or click below to log out.</p>
          </ng-container>
          <div>
            <button pstButton class="mt-6" (click)="logOut()">Log Out</button>
          </div>
        </ng-container>
        <ng-container *ngIf="!error()">
          <pst-spinner class="mt-16 inline-block" />
          <p class="mt-8">Logging you in...</p>
        </ng-container>
      </div>

      <p
        *ngIf="error()"
        class="border-l-error bg-accent-surface mt-16 inline-block border-l-4 p-4"
      >
        {{ error() }}
      </p>
    </div>
  `,
})
export class CustomOktaCallbackComponent implements OnInit {
  protected error = signal<string | undefined>(undefined);
  protected isNoAccessError = computed(() => {
    const error = this.error();
    // The full error is "OAuthError: User is not assigned to the client application."
    return error && error.includes('User is not assigned');
  });

  #config = inject(OKTA_CONFIG);
  #oktaAuth = inject(OKTA_AUTH);
  #injector = inject(Injector);

  async ngOnInit(): Promise<void> {
    try {
      // Parse code or tokens from the URL, store tokens in the TokenManager, and redirect back to the originalUri
      await this.#oktaAuth.handleLoginRedirect();
    } catch (e: unknown) {
      // Callback from social IDP. Show custom login page to continue.
      const isInteractionRequiredError =
        this.#oktaAuth.idx.isInteractionRequiredError;
      if (isInteractionRequiredError(e as Error)) {
        const { onAuthResume, onAuthRequired } = this.#config;
        const callbackFn = onAuthResume || onAuthRequired;
        if (callbackFn) {
          callbackFn(this.#oktaAuth, this.#injector);
          return;
        }
      }
      this.error.set((e as Error).toString());
      console.error(this.error);
    }
  }

  protected async logOut() {
    await this.#oktaAuth.signOut();
  }
}
