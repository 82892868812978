import { NgIf } from '@angular/common';
import { Component, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SpinnerComponent } from '@pst/spinner';

@Component({
  standalone: true,
  imports: [NgIf, RouterOutlet, SpinnerComponent],
  selector: 'pst-root',
  template: `
    <router-outlet (activate)="routerInitializing.set(false)" />
    <div
      class="absolute inset-0 flex h-screen w-screen items-center justify-center"
      *ngIf="routerInitializing()"
    >
      <pst-spinner />
    </div>
  `,
  host: { class: 'relative' },
})
export class AppComponent {
  routerInitializing = signal(true);
}
