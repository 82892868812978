// TODO: define category names in the database
export function getCategoryName(categoryCode: string): string {
  return (
    categoryCode
      .split('-')
      .filter((word) => word.length > 0)
      // "INBOUND-RESV" -> "Inbound Reservations", "SMOKE-TEST", "Smoke Test"
      .map((word) => {
        if (word === 'RESV') {
          word = 'Reservations';
        } else if (word === 'SYNC') {
          word = 'Synchronization';
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return `${word[0]!.toUpperCase()}${word.slice(1).toLowerCase()}`;
      })
      .join(' ')
  );
}
