import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  CdkMenu,
  CdkMenuItem,
  CdkMenuTrigger,
  CdkMenuItemRadio,
} from '@angular/cdk/menu';
import { IconDirective } from '@pst/icon';

import { ColorScheme, ThemeService } from '../theme.service';

@Component({
  selector: 'pst-header-menu',
  standalone: true,
  imports: [
    CdkMenu,
    CdkMenuItem,
    CdkMenuItemRadio,
    CdkMenuTrigger,
    IconDirective,
    NgFor,
    NgIf,
    RouterLink,
  ],
  template: `
    <button [cdkMenuTriggerFor]="menu" class="flex h-14 items-center">
      <div class="mr-4 text-left">
        <ng-container *ngIf="userName">
          <div>{{ userName }}</div>
          <div>{{ selectedPropertyId }}</div>
        </ng-container>
        <div *ngIf="!userName">Menu</div>
      </div>
      <pst-icon>menu</pst-icon>
    </button>
    <ng-template #menu>
      <div class="bg-surface flex-col shadow-md" cdkMenu>
        <a
          class="text-foreground hover:bg-accent-surface focus:bg-accent-surface flex items-center justify-between px-4 py-3 outline-none"
          cdkMenuItem
          routerLink="/select-property"
          [queryParams]="{ returnUrl: currentUrl }"
          *ngIf="hasMultipleProperties && selectedPropertyId"
        >
          <span>Change Property</span>
          <pst-icon class="ml-4">swap_horiz</pst-icon>
        </a>
        <button
          type="button"
          cdkMenuItem
          [cdkMenuTriggerFor]="colorSchemeMenu"
          class="text-foreground hover:bg-accent-surface focus:bg-accent-surface flex w-full items-center justify-between px-4 py-3 outline-none"
        >
          <span>Color Scheme</span>
          <pst-icon>chevron_right</pst-icon>
        </button>
        <button
          type="button"
          class="text-foreground hover:bg-accent-surface focus:bg-accent-surface flex w-full items-center justify-between px-4 py-3 outline-none"
          cdkMenuItem
          *ngIf="userName"
          (click)="signOut.emit()"
        >
          <span>Sign Out</span>
          <pst-icon>logout</pst-icon>
        </button>
      </div>

      <ng-template #colorSchemeMenu>
        <div class="bg-surface flex-col shadow-md" cdkMenu>
          <button
            *ngFor="let scheme of colorSchemes"
            cdkMenuItemRadio
            [cdkMenuItemChecked]="theme.colorScheme() === scheme.value"
            (cdkMenuItemTriggered)="theme.selectColorScheme(scheme.value)"
            class="text-foreground hover:bg-accent-surface focus:bg-accent-surface aria-checked:bg-primary flex w-full items-center justify-between px-4 py-3 outline-none aria-checked:text-white"
          >
            {{ scheme.label }}
          </button>
        </div>
      </ng-template>
    </ng-template>
  `,
})
export class HeaderMenuComponent {
  @Input() userName?: string;

  @Input() selectedPropertyId: string | null = null;

  @Input() hasMultipleProperties = false;

  @Input() currentUrl = '';

  @Output() signOut = new EventEmitter<void>();

  protected theme = inject(ThemeService);

  colorSchemes: { value: ColorScheme; label: string }[] = [
    { value: 'light', label: 'Light Mode' },
    { value: 'dark', label: 'Dark Mode' },
    { value: 'system', label: 'Use System Setting' },
  ];
}
