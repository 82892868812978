import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { first, map } from 'rxjs';
import { SelectedPropertyService } from '@pst/selected-property';

import { UserService } from './user.service';

/**
 * A route guard that redirects to the property selection page if the user has no selected property id.
 */
export function selectedPropertyGuard(
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) {
  const router = inject(Router);

  const selectedProperty = inject(SelectedPropertyService);

  const propertyIdsQuery = inject(UserService).propertyIds;

  return toObservable(propertyIdsQuery.status).pipe(
    // wait for query to be successful or error
    first((propertyIdsQueryStatus) => propertyIdsQueryStatus !== 'pending'),
    map((_) => {
      const propertyIds = propertyIdsQuery.isSuccess()
        ? propertyIdsQuery.data()
        : [];
      const selectedPropertyId = selectedProperty.selectedPropertyId();
      if (selectedPropertyId) {
        if (propertyIds.includes(selectedPropertyId)) {
          return true;
        }
        selectedProperty.selectPropertyId('');
        return router.createUrlTree(['/select-property'], {
          queryParams: { returnUrl: state.url },
        });
      }

      if (propertyIds.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const onlyPropertyId = propertyIds[0]!;
        if (selectedPropertyId !== onlyPropertyId) {
          selectedProperty.selectPropertyId(onlyPropertyId);
        }
        return true;
      }

      return router.createUrlTree(['/select-property'], {
        queryParams: { returnUrl: state.url },
      });
    }),
  );
}
