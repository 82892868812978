import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, of } from 'rxjs';

const vendorPropertyIdsKey = 'vendorPropertyIds';

interface VendorPropertyIds {
  vendorCode: string;
  propertyIds: string[];
}

interface GetPropertyIdsResponse {
  status: 0 | 1;
  message: string;
  data?: string[];
}

@Injectable({ providedIn: 'root' })
export class VendorPropertyIdsService {
  #httpClient = inject(HttpClient);

  clearVendorPropertyIds() {
    localStorage.removeItem(vendorPropertyIdsKey);
  }

  storeVendorPropertyIds(vendorPropertyIds: VendorPropertyIds | null) {
    if (vendorPropertyIds) {
      localStorage.setItem(
        'vendorPropertyIds',
        JSON.stringify(vendorPropertyIds)
      );
    } else {
      localStorage.removeItem(vendorPropertyIdsKey);
    }
  }

  getVendorPropertyIds(vendorCode: string): Observable<string[]> {
    const cachedPropertyIds = this.#getCachedVendorPropertyIds(vendorCode);
    if (cachedPropertyIds) {
      return of(cachedPropertyIds);
    }
    return this.#httpClient.get<GetPropertyIdsResponse>('/getpropertyids').pipe(
      map((response) => {
        if (response.status !== 1) {
          throw new Error(response.message);
        }
        const propertyIds = response.data ?? [];
        this.storeVendorPropertyIds({
          vendorCode,
          propertyIds,
        });
        return propertyIds;
      })
    );
  }

  #getCachedVendorPropertyIds(vendorCode: string): string[] | null {
    const storedValue = localStorage.getItem(vendorPropertyIdsKey);
    if (!storedValue) {
      return null;
    }
    try {
      const value = JSON.parse(storedValue);
      if (this.#isVendorPropertyIds(value)) {
        if (value.vendorCode !== vendorCode) {
          // if the vendor code doesn't match what's stored, clear the stored value
          this.storeVendorPropertyIds(null);
          return null;
        }
        return value.propertyIds;
      }
      return null;
    } catch (e: unknown) {
      return null;
    }
  }

  #isVendorPropertyIds(
    value: unknown
  ): value is { vendorCode: string; propertyIds: string[] } {
    return (
      typeof value === 'object' &&
      value != null &&
      typeof (value as VendorPropertyIds).vendorCode === 'string' &&
      Array.isArray((value as VendorPropertyIds).propertyIds)
    );
  }
}
