export type CallState<E = unknown> = 'idle' | 'loading' | 'success' | E;

export interface LoadingResult {
  status: 'loading';
  data: undefined;
  error: undefined;
  isError: false;
  isSuccess: false;
  isLoading: true;
}

export interface SuccessResult<T> {
  status: 'success';
  data: T;
  error: undefined;
  isError: false;
  isSuccess: true;
  isLoading: false;
}

export interface ErrorResult<E = unknown> {
  status: 'error';
  data: undefined;
  error: E;
  isError: true;
  isSuccess: false;
  isLoading: false;
}

export interface IdleStatus<T> {
  status: 'idle';
  data: T | undefined;
  error: undefined;
  isError: false;
  isSuccess: false;
  isLoading: false;
}

export type QueryState<T, E = unknown> =
  | LoadingResult
  | SuccessResult<T>
  | ErrorResult<E>
  | IdleStatus<T>;

export function initialQueryState<T, E>(initialData?: T): QueryState<T, E> {
  return {
    status: 'idle',
    data: initialData,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
  };
}

export function loadingResult<T, E>(): QueryState<T, E> {
  return {
    status: 'loading',
    data: undefined,
    error: undefined,
    isLoading: true,
    isError: false,
    isSuccess: false,
  };
}

export function successResult<T, E>(data: T): QueryState<T, E> {
  return {
    status: 'success',
    data,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: true,
  };
}

export function errorResult<T, E>(error: E): QueryState<T, E> {
  return {
    status: 'error',
    data: undefined,
    error,
    isError: true,
    isLoading: false,
    isSuccess: false,
  };
}
