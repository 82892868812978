import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonDirective } from '@pst/button';

@Component({
  standalone: true,
  selector: 'pst-header-nav',
  imports: [ButtonDirective, RouterLink],
  template: `
    <nav class="flex">
      <!-- TODO: do we need <ul> here? -->
      <a
        pstButton
        color="accent"
        class="ml-2 !text-base !tracking-normal"
        routerLink="/precertification"
      >
        Precertification
      </a>
      <a
        pstButton
        color="accent"
        class="!text-base !tracking-normal"
        routerLink="/categories"
      >
        Testing
      </a>
      <a
        pstButton
        color="accent"
        class="ml-2 !text-base !tracking-normal"
        routerLink="/logs"
      >
        Log Viewer
      </a>
      @if (isAdmin()) {
        <a
          pstButton
          color="accent"
          class="ml-2 !text-base !tracking-normal"
          routerLink="/admin"
        >
          Admin
        </a>
      }
    </nav>
  `,
})
export class HeaderNavComponent {
  isAdmin = input.required<boolean>();
}
