import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { OKTA_AUTH } from '@okta/okta-angular';
import { environment } from '@pst/environment';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const apiUrl = environment.apiUrl;
  if (request.url.startsWith('/')) {
    request = request.clone({
      url: new URL(request.url, apiUrl).toString(),
    });
  }
  // if (request.url.startsWith(apiUrl)) {
  return next(
    request.clone({
      setHeaders: {
        authorization: 'Bearer ' + inject(OKTA_AUTH).getAccessToken(),
      },
    }),
  );
  // }
  return next(request);
};
