import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  type CanActivateFn,
  type NavigationExtras,
  Router,
} from '@angular/router';
import { filter, map } from 'rxjs';

import { CategoriesService } from './categories/categories.service';

export const hasSuccessfulPingTest = hasPingTestResult(
  true,
  ['/getting-started'],
  { skipLocationChange: true },
);
export const hasNoSuccessfulPingTest = hasPingTestResult(
  false,
  ['/categories'],
  { replaceUrl: true },
);

function hasPingTestResult(
  expectedHasPingValue: boolean,
  redirectOnFailureUrl: string[],
  redirectConfig: Partial<NavigationExtras>,
): CanActivateFn {
  return (_route, _state) => {
    const hasPing = inject(CategoriesService).hasSuccessfulPingTest;
    const router = inject(Router);

    return toObservable(hasPing).pipe(
      // wait until the result has been loaded before deciding where to route
      filter((hasPing): hasPing is boolean => hasPing !== undefined),
      map((hasPing) => {
        if (hasPing === expectedHasPingValue) {
          return true;
        }
        router.navigate(redirectOnFailureUrl, redirectConfig);
        return false;
      }),
    );
  };
}
