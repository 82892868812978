import { AppEnvironment } from './environment.interface';

export const environment: AppEnvironment = {
  apiUrl: 'https://test.pmsselftest.bwhhg.io/',
  adminUrl: 'TODO',
  oktaIssuer:
    'https://hotelsso.qa.bwhhotelgroup.com/oauth2/aus1unwr1liqmhNIA0h8',
  oktaClientId: '0oa1upjma9ii6W51i0h8',
  precertificationUrls: {
    getPresignedUrl:
      'https://jvsbmmvpb5.execute-api.us-west-2.amazonaws.com/stage/presign',
    postPrecertification:
      'https://8lakwtg7nj.execute-api.us-west-2.amazonaws.com/stage/precert',
  },
  logViewerUrls: {
    query: 'https://0ttuagxw91.execute-api.us-west-2.amazonaws.com/stage/query',
    status:
      'https://jh38li0cz8.execute-api.us-west-2.amazonaws.com/stage/status',
    logs: 'https://imv5y3okzi.execute-api.us-west-2.amazonaws.com/stage/logs',
  },
};
