import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { OktaAuthStateService } from '@okta/okta-angular';
import { UserClaims } from '@okta/okta-auth-js';
import { Observable, firstValueFrom, of } from 'rxjs';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { injectTrpcClient } from '@pst/trpc';

import { VendorPropertyIdsService } from './vendor-property-ids.service';

interface PropertyClaims {
  property_ids: string[];
}

interface VendorClaims {
  vendorCode: string;
  property_ids: string[];
}

type Claims = VendorClaims | PropertyClaims;

@Injectable({ providedIn: 'root' })
export class UserService {
  #authState = toSignal(inject(OktaAuthStateService).authState$);

  #vendorPropertyIds = inject(VendorPropertyIdsService);

  isAuthenticated = computed(() => this.#authState()?.isAuthenticated ?? false);

  #trpcClient = injectTrpcClient();

  #claims = computed((): (UserClaims & Claims) | null => {
    const authState = this.#authState();
    if (authState && authState.isAuthenticated && authState.idToken?.claims) {
      return authState.idToken.claims as UserClaims & Claims;
    }
    return null;
  });

  propertyIdsClaim = computed(() => {
    return this.#claims()?.property_ids.filter((p) => !!p.trim()) ?? [];
  });

  vendorCodeClaim = computed(() => {
    const claims = this.#claims() as VendorClaims | undefined;
    return claims?.vendorCode ?? '';
  });

  name = computed(() => this.#claims()?.name ?? '');

  username = computed(() => this.#authState()?.accessToken?.claims.sub ?? '');

  propertyIds = injectQuery(() => ({
    queryKey: [
      'currentUserPropertyIds',
      this.vendorCodeClaim(),
      this.propertyIdsClaim(),
    ],
    queryFn: () => {
      return firstValueFrom(
        this.#getVendorPropertyIds(
          this.vendorCodeClaim(),
          this.propertyIdsClaim(),
        ),
      );
    },
    enabled: this.isAuthenticated(),
  }));

  #getVendorPropertyIds(
    vendorCode: string,
    propertyIdsClaim: string[],
  ): Observable<string[]> {
    if (!vendorCode) {
      this.#vendorPropertyIds.storeVendorPropertyIds(null);
      return of(propertyIdsClaim.filter((p) => !!p.trim()));
    }
    return this.#vendorPropertyIds.getVendorPropertyIds(vendorCode);
  }

  isAdmin = injectQuery(() => ({
    queryKey: ['currentUserIsAdmin'],
    queryFn: () => this.#trpcClient.users.me.isAdmin.query(),
    enabled: this.isAuthenticated(),
  }));
}
