import { importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { TitleStrategy, provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  provideAngularQuery,
  QueryClient,
} from '@tanstack/angular-query-experimental';
import { oktaAuth } from '@pst/okta';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app-routes';
import { PrefixedTitleStrategy } from './app/title-strategy';
import { authInterceptor } from './okta-auth';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(OktaAuthModule),
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([authInterceptor])),
    { provide: TitleStrategy, useClass: PrefixedTitleStrategy },
    provideAngularQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
    ),
  ],
});
