import { OktaAuth } from '@okta/okta-auth-js';

import { environment } from '@pst/environment';

/**
 * The OktaAuth instances used by the self-test Angular application.
 * May be referenced directly, or injected with `inject(OKTA_AUTH)`
 */
export const oktaAuth = new OktaAuth({
  issuer: environment.oktaIssuer,
  redirectUri: window.location.origin + '/login/callback',
  clientId: environment.oktaClientId,
  services: { syncStorage: false },
  scopes: [
    // this custom scope ensures that we get 'property_ids' on the id/access tokens
    'PMS_Self_Test',
    'email',
    'profile',
    'openid',
  ],
  postLogoutRedirectUri: window.location.origin + '/logout',
});
