import { Injectable, computed, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { fromEvent, map, startWith, tap } from 'rxjs';

export const colorSchemes = ['light', 'dark', 'system'] as const;
export type ColorScheme = (typeof colorSchemes)[number];

function isColorScheme(arg: string | null): arg is ColorScheme {
  return arg != null && colorSchemes.includes(arg as ColorScheme);
}

type Theme = 'light' | 'dark';

interface ThemeState {
  colorScheme: ColorScheme;
}

const COLOR_SCHEME_KEY = 'theme';

@Injectable({ providedIn: 'root' })
export class ThemeService extends ComponentStore<ThemeState> {
  private prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

  colorScheme = this.selectSignal((s) => s.colorScheme);

  private mediaQueryTheme = toSignal(
    fromEvent<MediaQueryListEvent>(this.prefersDark, 'change').pipe(
      startWith(this.prefersDark),
      map((mediaQuery): Theme => (mediaQuery.matches ? 'dark' : 'light')),
    ),
    { requireSync: true },
  );

  theme = computed(() => {
    const colorScheme = this.colorScheme();
    if (colorScheme !== 'system') {
      return colorScheme as Theme;
    }
    return this.mediaQueryTheme();
  });

  constructor() {
    super();
    this.restorePersistedTheme();
    this.syncDom();
  }

  selectColorScheme = this.effect<ColorScheme>(($) => {
    return $.pipe(
      tap((colorScheme) => {
        this.setState({ colorScheme });
        this.persistColorScheme(colorScheme);
      }),
    );
  });

  private restorePersistedTheme(fallback: ColorScheme = 'system'): void {
    const persistedColorScheme = localStorage.getItem(COLOR_SCHEME_KEY);
    const colorScheme = isColorScheme(persistedColorScheme)
      ? persistedColorScheme
      : fallback;
    this.selectColorScheme(colorScheme);
  }

  private persistColorScheme(colorScheme: ColorScheme): void {
    localStorage.setItem(COLOR_SCHEME_KEY, colorScheme);
  }

  private syncDom() {
    effect(() => {
      const theme = this.theme();
      document.documentElement.style.colorScheme = theme;
      if (theme === 'dark') {
        document.body.classList.add('dark-mode');
      } else {
        document.body.classList.remove('dark-mode');
      }
    });
  }
}
