import { Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { selectedPropertyGuard } from '@pst/user';

import { AppLayoutComponent } from './app-layout/app-layout.component';
import { CustomOktaCallbackComponent } from './custom-okta-login-callback.component';
import {
  hasNoSuccessfulPingTest,
  hasSuccessfulPingTest,
} from './has-successful-ping.guard';
import { isAdmin } from './is-admin.guard';

export const appRoutes: Routes = [
  { path: 'login/callback', component: CustomOktaCallbackComponent },
  // TODO: conditionally redirect to /admin or /categories?
  { path: '', redirectTo: 'categories', pathMatch: 'full' },
  {
    path: 'health',
    loadComponent: () =>
      import('./health/health.component').then((m) => m.HealthComponent),
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    children: [
      {
        path: 'select-property',
        loadComponent: () =>
          import('@pst/select-property').then(
            (m) => m.PropertySelectPageComponent,
          ),
        title: 'Select a Property',
      },
      {
        path: 'admin',
        canActivate: [isAdmin],
        loadChildren: () =>
          import('@pst/admin-page').then((m) => m.adminRoutes),
      },
      {
        path: '',
        canActivateChild: [selectedPropertyGuard],
        children: [
          {
            path: 'precertification',
            loadComponent: () =>
              import('./precertification/precertification.component').then(
                (m) => m.PrecertificationComponent,
              ),
            title: 'Pre-Certification Testing',
          },
          {
            path: 'getting-started',
            loadComponent: () =>
              import('./getting-started/getting-started.component').then(
                (m) => m.GettingStartedComponent,
              ),
            title: 'Getting Started',
            canActivate: [hasNoSuccessfulPingTest],
          },
          {
            path: 'categories',
            loadChildren: () => import('./categories/routes'),
            canActivate: [hasSuccessfulPingTest],
            canActivateChild: [hasSuccessfulPingTest],
          },
          {
            path: 'logs',
            loadChildren: () => import('@pst/log-viewer').then((m) => m.routes),
          },
        ],
      },
      {
        path: '**',
        loadComponent: () =>
          import('./not-found/not-found.component').then(
            (m) => m.NotFoundComponent,
          ),
        title: 'Page Not Found',
      },
    ],
  },
];
